/* You can add global styles to this file, and also import other style files */
@import "@apaq/stallion/dist/stallion/stallion.css";

html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

html, body {
  margin: 0;
  height: 100%;
}

body{
  display: flex;
  //flex-direction: column;
  overflow: hidden;
  background-color: var(--sl-panel-background-color);
  touch-action: manipulation;
}

html {
  /** Over all size **/
  font-size: 14px;
}

@media (prefers-color-scheme: light) {
  :root {
    --sl-sidebar-view-background: #fcfcfc;
    --sl-sidebar-view-background-sidebar: var(--sl-sidebar-view-background);
  }
}

sl-sidebar-view::part(page-header) {
  // Force sidebarview header to match sidebar background
  --sl-panel-background-color: var(--sl-sidebar-view-background);
}

sl-sidebar-view {
  // Force sidebarview sub-header to match sidebar background
  background: var(--sl-sidebar-view-background);
}

sl-page-header::part(header) {
  color: var(--sl-color-blue-800);
}

app-root {
  flex: 1;
  display: flex;
}